import * as React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { signIn, SignInResponse } from 'next-auth/react'
import { Button } from 'lib/shared-ui'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import { ErrorText, Form, Input, Label, OTPInput } from 'lib/forms'
import { logEvent, setUserId, useLogOnRender } from 'lib/analytics'
import { switchInError } from '../../lib/switch-in'
import { useEffect } from 'react'
import { AuthBody, AuthHeader, AuthLayout } from '../../lib/layouts'
import api from '../../lib/api'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { GoogleButton } from './signup'
import { AuthFooter } from '../../lib/layouts/auth'

export default function SignInPage() {
    useLogOnRender('auth:signin-otp-page:view')

    const methods = useForm()
    const [isLoading, setIsLoading] = React.useState(false)
    const [isVerifying, setIsVerifying] = React.useState(false)
    const email = methods.watch('email')

    const router = useRouter()
    const { callbackUrl, errorId } = router.query

    useEffect(() => {
        if (errorId) {
            toast.error(
                switchInError(errorId as string, {
                    GOOGLE_EMAIL_NOT_VERIFIED:
                        'Your Google email address is not verified, please try with a different Google Account.',
                    USER_ALREADY_EXISTS:
                        'You already have an account, please sign in with your email.',
                })
            )
        }
    }, [errorId])

    const signInHandler = async ({
        email,
        password,
    }: Record<'email' | 'password', string>) => {
        setIsLoading(true)

        const res = await signIn('credentials', {
            email,
            password,
            mode: 'otp',
            redirect: false,
        })

        const { ok, error } = res as never as SignInResponse

        if (!ok) {
            toast.error(
                switchInError(error as string, {
                    CredentialsSignin: 'Wrong email or password.',
                })
            )
            setIsLoading(false)
            return
        }

        logEvent('auth:signed-in', { userEmail: email })
        setUserId(email)

        // TODO: check https://www.youtube.com/watch?v=kB6YNYZ63fw for a better solution
        signIn('credentials', {
            email,
            password,
            mode: 'otp',
            redirect: true,
            callbackUrl: callbackUrl as string,
        })
    }

    const sendOtp = useMutation(
        (data: Record<string, any>) => {
            return api.post('auth/send-otp', { email: data.email })
        },
        {
            onError: (data: AxiosError) => {
                toast.error(
                    switchInError(data, {
                        USER_NOT_FOUND:
                            'User with this email is not found, please sign up first or try with different email.',
                    })
                )
            },
            onSuccess: async () => {
                setIsVerifying(true)
            },
        }
    )

    return (
        <AuthLayout id="signin-form">
            <AuthHeader title="Sign in to your account" />

            <AuthBody>
                <Form
                    onSubmit={isVerifying ? signInHandler : sendOtp.mutate}
                    methods={methods}
                    className="space-y-5"
                >
                    <div>
                        <GoogleButton divider="Or sign in with email" />
                        <Label id="email">Email</Label>
                        <Input
                            id="email"
                            type="email"
                            className="mt-1"
                            rules={{ required: true }}
                        />
                        <ErrorText id="email">
                            Please enter a valid email address
                        </ErrorText>
                    </div>

                    {isVerifying && (
                        <div className="mb-5">
                            <Label id="password">Passcode</Label>
                            <div className="text-gray-600 mt-1 text-sm">
                                Enter the passcode that we just emailed to you.
                            </div>
                            <div className="flex flex-row mt-1 mb-4 font-medium text-sm">
                                <div
                                    className="flex text-indigo-600 cursor-pointer"
                                    onClick={() => sendOtp.mutate({ email })}
                                >
                                    Resend code
                                </div>
                                <span className="mx-2">or</span>
                                <Link href="/auth/signin-old" passHref>
                                    <a className="flex text-indigo-600 cursor-pointer">
                                        sign in with password
                                    </a>
                                </Link>
                            </div>
                            <OTPInput
                                id="password"
                                rules={{ required: true, minLength: 6 }}
                            />
                            <ErrorText id="password">
                                Please enter the passcode
                            </ErrorText>
                        </div>
                    )}
                    {sendOtp.isLoading && (
                        <div className="text-gray-600 text-sm">
                            Sending OTP...
                        </div>
                    )}

                    {isVerifying ? (
                        <Button variant="primary" className="w-full">
                            {isLoading ? 'Signing In...' : 'Sign in'}
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            className="w-full"
                            type="button"
                            onClick={() => sendOtp.mutate({ email })}
                        >
                            Continue
                        </Button>
                    )}
                </Form>
                <AuthFooter
                    links={[
                        {
                            href: '/auth/signin-old',
                            name: 'Login with password',
                        },
                        {
                            href: '/auth/signup',
                            name: 'Create account',
                        },
                    ]}
                />
            </AuthBody>
        </AuthLayout>
    )
}
